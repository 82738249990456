<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import { masterMethods, prizeMethods, listPaging, clientMethods, checkPermission, checkPermissionName } from '@/state/helpers';
import Pagination from '@/components/Pagination/main.vue';
import Multiselect from 'vue-multiselect';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

/**
 * Advanced-form component
 */
export default {
    page: {
        title: '賞品管理一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        KeyWordTag,
        Pagination,
        Layout,
        Multiselect,
        LoadingIcon,
        Footer
    },
    data() {
        return {
            listData: [],
            listDataCampaign: [],
            total: 0,
            limit: 10,
            page: 1,
            form: {
                name: '',
                remaining_quantity: ''
            },
            has: {
                return_items_existed: '',
                campaign: ''
            },
            perPageList: listPaging,
            filter: {
                type: '',
                remaining_quantity: '',
                other_than_zero: ''
            },
            date_from: {
                remaining_quantity: ''
            },

            campaign: {},
            customFormatDate: 'YYYY-MM-DD',
            customFormatMonth: 'YYYY-MM',
            options1: [],
            checkShowFilter: false,
            listTagFreeWord: [],
            listTagKeyWord: [],
            configKeyword: {
                arrayTag: [],
                placeholder: 'キャンペーン名　賞品名 ...',
                isShowLable: true
            },
            isLoading: false
        };
    },
    watch: {
        campaign: function (newName) {
            if (newName) this.has.campaign = newName.id;
            else this.has.campaign = '';
        },

        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getList();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getList();
            }
        },
        reload: function () {
            this.getList();
        },
        'configKeyword.arrayTag': function (newOld) {
            if (!this.checkShowFilter) {
                this.listTagFreeWord = newOld;
            } else {
                this.listTagKeyWord = newOld;
            }
        }
    },
    mounted() {
        this.getListCampaign();
        this.getList();
    },
    computed: {
        from: function () {
            return (this.page - 1) * this.limit + 1;
        },
        to: function () {
            let to = this.page * this.limit;
            return to > this.total ? this.total : to;
        }
    },
    methods: {
        checkPermission,
        checkPermissionName,
        ...masterMethods,
        ...prizeMethods,
        ...clientMethods,

        handleShowFilter() {
            this.checkShowFilter = !this.checkShowFilter;
            if (this.checkShowFilter) {
                this.configKeyword.placeholder = '';
                this.configKeyword.arrayTag = this.listTagKeyWord;
            } else {
                this.configKeyword.placeholder = 'キャンペーン名　賞品名 ...';
                this.configKeyword.arrayTag = this.listTagFreeWord;
            }
        },

        getListCampaign() {
            this.listMaster('all/campaigns').then((data) => {
                this.listDataCampaign = data;
            });
        },

        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];
            if (this.checkShowFilter) {
                if (this.form) {
                    for (const [key, value] of Object.entries(this.form)) {
                        if (key && value) query.push(`search[${key}]=${value}`);
                    }
                }

                if (this.filter) {
                    for (const [key, value] of Object.entries(this.filter)) {
                        if (key && value) query.push(`filters[${key}]=${value}`);
                    }
                }
                if (this.has) {
                    for (const [key, value] of Object.entries(this.has)) {
                        if (key && value) query.push(`has[${key}]=${value}`);
                    }
                }
                if (this.date_from) {
                    let date_from = Object.assign({}, this.date_from);
                    for (const [key, value] of Object.entries(date_from)) {
                        if (key && value) {
                            if (key == 'remaining_quantity') {
                                query.push(`from[${key}]=1`);
                            } else {
                                query.push(`from[${key}]=${value}`);
                            }
                        }
                    }
                }
            } else {
                if (this.configKeyword.arrayTag.length > 0) {
                    query.push(`q=${this.configKeyword.arrayTag.toString()}`);
                }
            }
            return query.join('&');
        },
        getList() {
            this.isLoading = true;
            let query = this.getQueryString();
            this.listPrize(query).then((data) => {
                this.listData = data.data;
                this.total = data._metadata.total;
                this.limit = data._metadata.limit * 1;
                if (!this.listData.length && this.page !== 1) {
                    this.page = 1;
                }
                this.isLoading = false;
            });
        },
        onChange() {
            this.page = 1;
        },
        search() {
            this.getList();
        },
        clear() {
            this.has = {
                return_items_existed: ''
            };
            this.configKeyword = {
                arrayTag: [],
                placeholder: 'キャンペーン名名　賞品名 ...',
                isShowLable: true
            };

            this.form = {
                name: '',
                remaining_quantity: ''
            };
            this.filter = {
                type: '',
                remaining_quantity: '',
                other_than_zero: ''
            };
            this.campaign = {};
            this.getList();
        },
        goToViewCampaign(path, id) {
            this.$router.push({ path: path, query: { id: id } });
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">賞品検索</h3>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" autocomplete="off">
                                    <div v-if="!checkShowFilter" class="row form-group">
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" class="" />
                                        </div>
                                    </div>

                                    <div v-if="checkShowFilter">
                                        <div class="form-group row" v-if="!checkPermissionName(['partner'])">
                                            <label class="col-sm-3" for="validationCustom05">賞品名</label>
                                            <div class="col-sm-9">
                                                <input id="validationCustom05" v-model="form.name" type="text" class="form-control" />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">キャンペーン名</label>
                                            <div class="col-sm-9">
                                                <multiselect
                                                    :showPointer="false"
                                                    :showNoResults="false"
                                                    selectedLabel=""
                                                    deselectLabel=""
                                                    placeholder=""
                                                    selectLabel=""
                                                    :showNoOptions="false"
                                                    v-model="campaign"
                                                    track-by="id"
                                                    label="name"
                                                    :options="listDataCampaign"
                                                >
                                                </multiselect>
                                                <i
                                                    v-if="campaign && campaign.id"
                                                    @click="
                                                        $event.stopPropagation();
                                                        campaign = {};
                                                    "
                                                    style="cursor: pointer; position: absolute; right: 23px; z-index: 9; top: 11px; background: #fff"
                                                    class="fa fa-window-close"
                                                ></i>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom05">残数</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center">
                                                    <input
                                                        id="validationCustom05"
                                                        v-model="filter.remaining_quantity"
                                                        type="text"
                                                        style="width: 180px"
                                                        class="form-control"
                                                    />
                                                    <div class="custom-control custom-checkbox ml-4">
                                                        <input
                                                            type="checkbox"
                                                            v-model="date_from.remaining_quantity"
                                                            id="checkd"
                                                            :value="1"
                                                            class="custom-control-input"
                                                        />
                                                        <label for="checkd" class="custom-control-label">0以外</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-3" for="validationCustom011">賞品戻り</label>
                                            <div class="col-sm-9">
                                                <div class="d-flex align-items-center">
                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            v-model="has.return_items_existed"
                                                            id="checkdd"
                                                            :value="1"
                                                            class="custom-control-input"
                                                        />
                                                        <label for="checkdd" class="custom-control-label">あり</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="text-center mt-4 position-relative">
                                        <button type="button" @click="clear()" class="btn btn-light">クリア</button>
                                        <button type="button" @click="search()" class="btn btn-primary ml-3">検索</button>

                                        <a @click="handleShowFilter" class="filter" href="javascript:void(0)">
                                            <img :src="require('@/assets/images/settings.png')" width="30px" height="30px" alt="" />
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <h3 class="mb-0">
                            賞品検索結果 <span>件数（{{ total }})</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="pagination__items clearfix pb-3 text-right">
                            <span class="pagination__items__label mr-4"> {{ total }} 件中 {{ from }}-{{ to }} 件表示 </span>
                            <span>表示件数</span>
                            <select v-model="limit" class="pagination__items__select ml-2" @change="onChange">
                                <option v-for="value in perPageList" :key="value" :label="value + '件'" :value="value"></option>
                            </select>
                        </div>
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th>キャンペーン名</th>
                                        <th>賞品名</th>
                                        <th>残数</th>
                                        <th>賞品戻り数</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="listData.length > 0 && !isLoading">
                                    <template v-for="item in listData">
                                        <tr :key="item.id">
                                            <td>
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="goToViewCampaign('/campaign/form/view', item.campaign_event_prize?.campaign?.id ?? '')"
                                                >
                                                    {{ item.campaign_event_prize?.campaign?.name ?? '' }}</a
                                                >
                                            </td>

                                            <td>{{ item.name }}</td>

                                            <td>{{ item.remaining_quantity }}</td>
                                            <td>{{ item.return_items_count }}</td>

                                            <td align="center">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="text-info border-right-1"
                                                    @click="goToEdit('/prize/form/view', item)"
                                                    >詳細</a
                                                >
                                                <a
                                                    v-if="checkPermission('prize.register')"
                                                    href="javascript:void(0)"
                                                    class="text-success"
                                                    @click="goToEdit('/prize/form', item)"
                                                    >編集</a
                                                >
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else-if="isLoading">
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <div class="d-flex justify-content-center">
                                                    <LoadingIcon />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody v-else>
                                    <template>
                                        <tr style="position: relative; height: 80px">
                                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                                <p class="mt-3 text-center">
                                                    {{ $t('notificationCommon.searchEmpty') }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>

                        <pagination v-if="total && listData.length > 0 && !isLoading" :total="total" :page.sync="page" :per-page="limit"></pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
